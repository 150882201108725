import { createContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [approved, setApproved] = useState(0);
  const [adminAuthenticated, setAdminAuthenticated] = useState(false);
const [currBalance, setCurrBalance] = useState(0);
const [isRejected, setIsRejected] = useState(false);
const [Userid , setUserid ] = useState();
const [paymentOk , setPaymentOk ] = useState(0);
const [newId,setnewId] =useState(null)
 const [today_team, setToday_team] = useState(0);
const [dp , setDp] = useState(null)
const [NewName, setName] = useState('');
const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false); // <-- Added this state
const [backend_wallet, setBackend_wallet] = useState(0);
const [level,setLevel] = useState(0)
const [week_team, setWeek_team] = useState(0);
const [today_wallet, setToday_wallet] = useState(0);
const [totalWithdrwa, setTotalWithdrwa] = useState(0);
const fetchUserData = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/getUserData`,     { withCredentials: true }
    );

    if (response.data.Status === 'Success') {
      setUserData(response.data.Data);
      setApproved(response.data.Data.approved);
      setIsAuthenticated(true); 
      setName(response.data.Data.name);
      setCurrBalance(response.data.Data.balance);
      setIsRejected(response.data.Data.rejected);
      setUserid(response.data.Data.id);
      setPaymentOk(response.data.Data.payment_ok);
      setDp(response.data.Data.profile_picture);
      localStorage.setItem('userApproved', response.data.Data.approved);
      localStorage.setItem('userAuthenticated', true); 
      setBackend_wallet(response.data.Data.backend_wallet);
      setToday_team(response.data.Data.today_team);
      setLevel(response.data.Data.level);
      setWeek_team(response.data.Data.week_team);
      setToday_wallet(response.data.Data.today_wallet);
      setTotalWithdrwa(response.data.Data.total_withdrawal);
      
    } else {
      console.error('Error:', response.data.Error);
      setIsAuthenticated(false);
    }
  } catch (error) {
    console.error('An error occurred while fetching user data:', error);
    setIsAuthenticated(false); 
  }
  finally {
    setIsAuthCheckComplete(true); 
  }
};

  const logout = async () => {
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/logout`, {}, { withCredentials: true });
      if (response.data.Status === 'Success') {

        setUserData(null);
        setIsAuthenticated(false);
        setApproved(0);
        localStorage.removeItem('userApproved');
        localStorage.removeItem('userAuthenticated'); 
        window.location.replace('/');
      } else {
        console.error('Logout failed:', response.data.Error);
      }
    } catch (error) {
      console.error('An error occurred while logging out:', error);
    }
  };
  return (
    <UserContext.Provider value={{
      setnewId,
      Userid,
      paymentOk,
      currBalance,
      isAuthCheckComplete,
      approved,
      userData,
      isRejected,
      fetchUserData,
      isAuthenticated,
      logout,
      adminAuthenticated,
      setAdminAuthenticated,
      NewName,
      dp,
      backend_wallet,
      level,
      today_team,
      week_team,
      today_wallet,
      totalWithdrwa
    }}>
      {children}
    </UserContext.Provider>
  );
};
