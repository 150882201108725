import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import Modal from 'react-modal';
import { Button, Spinner, Table, } from 'react-bootstrap';

Modal.setAppElement('#root');

const ApprovedUsers = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/rejectedUsers`)
                
            .then(response => {
                setIsLoading(true);
                if (response.data && response.data.approvedUsers) {
                    setData(response.data.approvedUsers);
                    setFilteredData(response.data.approvedUsers);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the approved users!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setFilteredData(data.filter(user =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.id.toString().includes(searchTerm.toLowerCase()) ||
            user.trx_id.toString().includes(searchTerm.toLowerCase())
        ));
    }, [searchTerm, data]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClearOldRecords = () => {
        const shouldDelete = window.confirm(
            "Deleting 7 Days Old Records"
        );

        if (shouldDelete) {
            axios.delete(`${import.meta.env.VITE_API_BASE_URL}/delete-old-rejected-users`)
                .then(response => {
                    if (response.data.message === "No records deleted") {
                        alert("No records matched the criteria.");
                    } else {
                        alert("Old rejected user records deleted successfully");
                        // Refresh the data after deletion
                        axios.get(`${import.meta.env.VITE_API_BASE_URL}/rejectedUsers`)
                            .then(response => {
                                if (response.data && response.data.approvedUsers) {
                                    setData(response.data.approvedUsers);
                                    setFilteredData(response.data.approvedUsers);
                                }
                            });
                    }
                })
                .catch(error => {
                    console.error("There was an error deleting old records", error);
                });
        }
    };

    const handleClearRejectedUsers = () => {
        const shouldDelete = window.confirm(
            "Are you sure you want to delete all rejected users?"
        );

        if (shouldDelete) {
            axios.delete(`${import.meta.env.VITE_API_BASE_URL}/delete-rejected-users`)
                .then(response => {
                    if (response.data.message === "No rejected users to delete") {
                        alert("No rejected users to delete.");
                    } else {
                        alert("Rejected users deleted successfully");
                        // Refresh the data after deletion
                        axios.get(`${import.meta.env.VITE_API_BASE_URL}/rejectedUsers`)
                            .then(response => {
                                if (response.data && response.data.approvedUsers) {
                                    setData(response.data.approvedUsers);
                                    setFilteredData(response.data.approvedUsers);
                                }
                            });
                    }
                })
                .catch(error => {
                    console.error("There was an error deleting rejected users", error);
                });
        }
    };

    const handleApprove = (userId) => {
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/approveUser/${userId}`, { approved: 1, approved_at: new Date() })
            .then(() => {
                const updatedData = data.filter(user => user.id !== userId);
                setData(updatedData);
                setFilteredData(updatedData);
            })
            .catch(error => {
                console.error("There was an error approving the user!", error);
            });
    };

    

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <div className="listContainer">
                    {
                    isLoading ? (
                        <>
                        <div className="d-flex justify-content-center align-items-center">
                     <Spinner animation="border" role="status">
                       <span className="visually-hidden">Loading...</span>
                     </Spinner>
                   </div>
                       </>
                    
                    ) : (
                        <>
                            <input
                                className='search-bar'
                                type="text"
                                placeholder="Search..."
                                onChange={handleSearch}
                            />
                            <Button
                                variant='danger'
                                style={{ marginLeft: '10px' }}
                                onClick={handleClearOldRecords}
                            >
                                Clear 7 Days Old Records
                            </Button>
                            <Button
                                variant='danger'
                                style={{ marginLeft: '10px' }}
                                onClick={handleClearRejectedUsers}
                            >
                                Clear All Records
                            </Button>
                            {filteredData.length === 0 ? (
                                <p>No data available</p>
                            ) : (
                                <Table striped bordered hover>
                                    <thead style={{ backgroundColor: '#008cff', color: '#FFFFFF' }}>
                                        <tr>
                                            <th  className="table-header bg-primary text-light">ID</th>
                                            <th  className="table-header bg-primary text-light">Name</th>
                                            <th  className="table-header bg-primary text-light">Email</th>
                                            <th   className="table-header bg-primary text-light">TRX ID</th>
                                            <th  className="table-header bg-primary text-light">Sender Name</th>
                                            <th  className="table-header bg-primary text-light">Sender #</th>
                                            <th  className="table-header bg-primary text-light">ReferrBy</th>
                                            <th  className="table-header bg-primary text-light">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map(user => (
                                            <tr key={user.id}>
                                                <td>{user.id}</td>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.trx_id}</td>
                                                <td>{user.sender_name}</td>
                                                <td>{user.sender_number}</td>
                                                <td>{user.refer_by}</td>
                                                <td>
                                                    <Button
                                                        variant="success"
                                                        onClick={() => handleApprove(user.id)}
                                                    >
                                                        Approve
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ApprovedUsers;
