import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import Modal from 'react-modal';
import '../Users/users.css';
import { Button, Spinner } from 'react-bootstrap'; // Import Bootstrap Button

Modal.setAppElement('#root');

const ApprovedUsers = () => {
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editingUser, setEditingUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
    const [totalPages, setTotalPages] = useState(0);
    const [originalData, setOriginalData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const [emailSearchTerm, setEmailSearchTerm] = useState('');
    const token = localStorage.getItem('adminToken');

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/approved-users`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.data.success) {
                    const receivedData = response.data.approvedUsers;
                    setOriginalData([...receivedData]);
                    setData([...receivedData]);
                    setTotalPages(Math.ceil(receivedData.length / itemsPerPage));
                    setDisplayedData(receivedData.slice(0, itemsPerPage));
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the approved users!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleSearchUsingEmail = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        setTimeout(() => {
            const emailSearchTerm = `${searchTerm} `;
            setEmailSearchTerm(emailSearchTerm);

            axios.get(`${import.meta.env.VITE_API_BASE_URL}/users-by-email?email=${emailSearchTerm}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.success) {
                        const receivedData = response.data.users;
                        setOriginalData([...receivedData]);
                        setData([...receivedData]);
                        setTotalPages(Math.ceil(receivedData.length / itemsPerPage));
                        setDisplayedData(receivedData.slice(0, itemsPerPage));
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error("There was an error fetching users by email!", error);
                });
        }, 1000);
    };

    const handleReject = (userId) => {
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/rejectUser/${userId}`)
            .then(response => {
                const updatedData = data.filter(user => user.id !== userId);
                setData(updatedData);
            })
            .catch(error => {
                console.error("There was an error rejecting the user!", error);
            });
    };

    const handleEdit = (user) => {
        setEditingUser(user);
        setModalIsOpen(true);
    };

    const handleSave = () => {
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/updateUser`, editingUser)
            .then(response => {
                setModalIsOpen(false);
                const updatedData = data.map(user =>
                    user.id === editingUser.id ? editingUser : user);
                setData(updatedData);
            })
            .catch(error => {
                console.error("There was an error updating the user!", error);
            });
    };

    const sortData = () => {
        const sortedData = [...data];
        if (sortConfig.key !== null) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    };

    useEffect(() => {
        const sortedData = sortData();
        setData(sortedData);
    }, [sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        const sortedData = sortData();
        setData(sortedData);
    };

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedData(data.slice(startIndex, endIndex));
    }, [currentPage, itemsPerPage, data]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
             
                <div className="listContainer">
                    <div className="inputcont">
                        <input
                            className='search-bar'
                            type="text"
                            placeholder="Search Using Email"
                            onChange={handleSearchUsingEmail}
                        />
                    </div>

                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                        <table className="table table-striped">
                            <thead>
                                <tr className="w-100">
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('id')}>ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('name')}>Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('email')}>Email {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('phoneNumber')}>Phone {sortConfig.key === 'phoneNumber' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>

                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('balance')}>Balance {sortConfig.key === 'balance' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('backend_wallet')}>Backend {sortConfig.key === 'backend_wallet' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('trx_id')}>TRX ID {sortConfig.key === 'trx_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('total_withdrawal')}>Withdrawal {sortConfig.key === 'total_withdrawal' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('CurrTeam')}>CurrTeam {sortConfig.key === 'CurrTeam' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('team')}>Team {sortConfig.key === 'team' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('refer_by')}>ReferBy {sortConfig.key === 'refer_by' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('password')}>Password {sortConfig.key === 'password' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedData.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phoneNumber}</td>

                                        <td>{user.balance}</td>
                                        <td>{user.backend_wallet}</td>
                                        <td>{user.trx_id}</td>
                                        <td>{user.total_withdrawal}</td>
                                        <td>{user.CurrTeam}</td>
                                        <td>{user.team}</td>
                                        <td>{user.refer_by}</td>
                                        <td>{user.password}</td>
                                        <td className="action-buttons p-3">
                                            <Button variant="danger" onClick={() => handleReject(user.id)}>Reject</Button>
                                            {/* <Button variant="warning" onClick={() => handleEdit(user)} className="ms-2">Edit</Button> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    <div className="pagination d-flex align-items-center mt-3">
                        <Button variant="secondary" onClick={handlePreviousPage} disabled={currentPage === 1}>
                            <FaArrowAltCircleLeft />
                            &nbsp; Backward
                        </Button>
                        <Button variant="success" onClick={handleNextPage} disabled={currentPage === totalPages} className="ms-2">
                            Forward &nbsp;
                            <FaArrowAltCircleRight />
                        </Button>
                    </div>
                    <Modal isOpen={modalIsOpen} className="modal-content">
                        <h2>Edit User</h2>
                        <form>
                            <div className="mb-3">
                                <label>ID:</label>
                                <input type="text" value={editingUser.id} readOnly className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Name:</label>
                                <input type="text" value={editingUser.name} onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Email:</label>
                                <input type="email" value={editingUser.email} onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Password:</label>
                                <input type="text" value={editingUser.password} onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Balance:</label>
                                <input type="number" value={editingUser.balance} onChange={(e) => setEditingUser({ ...editingUser, balance: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Current Team:</label>
                                <input type="number" value={editingUser.CurrTeam} onChange={(e) => setEditingUser({ ...editingUser, CurrTeam: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>TRX ID:</label>
                                <input type="text" value={editingUser.trx_id} onChange={(e) => setEditingUser({ ...editingUser, trx_id: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Total Withdraw:</label>
                                <input type="number" value={editingUser.total_withdrawal} onChange={(e) => setEditingUser({ ...editingUser, total_withdrawal: e.target.value })} className="form-control" />
                            </div>
                            <Button type="button" onClick={handleSave} className="save-button" variant="primary">Save</Button>
                            <Button onClick={() => setModalIsOpen(false)} className="close-button" variant="secondary">Close</Button>
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default ApprovedUsers;
