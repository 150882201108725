import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../SideBarSection/Sidebar';
import '../Widgets/widgets.css';
import ApprovedUsers from '../Users/ApprovedUsers';
import ApprovedUsersSpec from '../Users/ApprovedUsersSpec';

const Widgets = () => {
  const [dashboardData, setDashboardData] = useState({
    approvedUsersCount: 0,
    approvedUsersCountToday: 0,
    totalReceived: 0,
    totalReceivedToday: 0,
    totalWithdrawal: 0,
    totalAmountTodayWithdrawal: 0,
    unapprovedUnpaidUsersCount: 0,
    totalAmountThisMonth: 0, // New state for monthly withdrawals
    totalReceivedThisMonth: 0, // New state for monthly received amounts
});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/dashboard-data`);
        setDashboardData(response.data.dashboardData || {});
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        {loading ? (
          <div className="loader-bar"></div>
        ) : (
          <>
            <div className="widgetss">
              <h1>Dashboard</h1>
            </div>
            <div className="task-container">
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Total Approved Users</span>
                </div>
                <h1>{dashboardData.approvedUsersCount}</h1>
              </div>

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Approved Users Today</span>
                </div>
                <h1>{dashboardData.approvedUsersCountToday}</h1>
              </div>

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Total Received</span>
                </div>
                <h1>{dashboardData.totalReceived}</h1>
              </div>

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Today Received</span>
                </div>
                <h1>{dashboardData.totalReceivedToday || 0}</h1>
              </div>

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Total Withdrawal</span>
                </div>
                <h1>{dashboardData.totalWithdrawal}</h1>
              </div>

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Today Withdrawal</span>
                </div>
                <h1>{dashboardData.totalAmountTodayWithdrawal || 0}</h1>
              </div>

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Pending Users</span>
                </div>
                <h1>{dashboardData.unapprovedUnpaidUsersCount}</h1>
              </div>
              <div className="task" draggable="true">
    <div className="tags">
      <span className="tag"> Month Received</span>
    </div>
    <h1>{dashboardData.totalReceivedThisMonth || 0}</h1>
  </div>

  <div className="task" draggable="true">
    <div className="tags">
      <span className="tag">Month Withdrawal</span>
    </div>
    <h1>{dashboardData.totalAmountThisMonth || 0}</h1>
  </div>
              <div className="task-container">
 
</div>

            </div>






<ApprovedUsersSpec/>


          </>
        )}
      </div>
    </div>
  );
};

export default Widgets;
