import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import toast, { Toaster } from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For eye icons

const GetWithdraw = () => {
    const [min, setMin] = useState('');
    const [withdrawLimit, setWithdrawLimit] = useState([]);
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const { userData, fetchUserData, level } = useContext(UserContext);
    const [balance, setBalance] = useState(0);
    const [err, setErr] = useState('');
    const [teamLen, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submittingWithdraw, setSubmittingWithdraw] = useState(false);
    const [showAccountNumber, setShowAccountNumber] = useState(false); // New state for toggle

    useEffect(() => {
        if (userData) {
            const updatedBalance = userData.balance ?? 0;
            setBalance(updatedBalance);
            setTeamMembers(userData.team ?? []);
            setLoading(false);
        }
    }, [userData]);

    useEffect(() => {
        fetchWithdrawLimits();
        fetchUserData();
    }, []);

    useEffect(() => {
        if (withdrawLimit.length > 0) {
            const result = withdrawLimit.find(item => item.level === level);
            if (result) {
                setMin(`Your Minimum Withdraw Limit is $${result.min}`);
            } else {
                console.log(`No data found for level ${level}`);
            }
        }
    }, [withdrawLimit, level]);

    const submitWithdrawalRequest = async () => {
        const phoneRegex = /^(?:\+92|0)?(?:\d{10}|\d{3}-\d{7}|\d{4}-\d{6})$/;

        if (!phoneRegex.test(accountNumber)) {
            setErr('Invalid Phone Number!');
            return;
        }

        try {
            const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/withdraw`, {
                amount: balance,
                accountName,
                accountNumber,
                bankName,
                team: teamLen,
                totalWithdrawn: userData.total_withdrawal,
            }, { withCredentials: true });

            if (response.data.status === 'success') {
                toast.success('Your withdrawal request has been submitted!');
                setAccountName('');
                setAccountNumber('');
                setBankName('');
                setErr('');
                setSubmittingWithdraw(false);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(`${error.response.data.error} ${min}`);
            } else {
                toast.error('An unexpected error occurred.');
            }
            setSubmittingWithdraw(false);
        }
    };

    const fetchWithdrawLimits = async () => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/fetchLimitsData`);
            if (response.data.status === 'success') {
                setWithdrawLimit(response.data.data);
                setLoading(false);
            } else {
                console.error('Error fetching withdraw limits:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching withdraw limits:', error);
        }
    };

    const RemoveTrailingZeros = (num) => {
        return num.toString().replace(/\.?0+$/, "");
    };

    if (loading) {
        return (
          <div className="loader__container">
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
          </div>
        );
    }

    return (
        <>
            <Toaster />
            <div className="m-3">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <p className='h4 font-bold text-white '>Available Balance</p>
                    <p className='h1blnc font-bold text-white'>
                        <span className='text-success font-bold me-1'>USD</span>{RemoveTrailingZeros(balance)}
                    </p>
                </div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    submitWithdrawalRequest();
                }} className='p-4 rounded shadow'>
                    <div className="mb-3">
                        <label className="form-label text-white font-bold" htmlFor="bankName">Bank Name</label>
                        <select
                            className="form-select mb-4"
                            id="bankName"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select Bank</option>
                            <option value="EasyPaisa">EasyPaisa</option>
                            <option value="SadaPay">SadaPay</option>
                            <option value="IslamicBank">Islamic Bank</option>
                            <option value="JazzCash">JazzCash</option>
                            <option value="HBL">HBL</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label text-white font-bold" htmlFor="accountNumber">Account Number</label>
                        {err && <p className="text-danger">{err}</p>}
                       <div className="input-group">
    <input
        className="form-control bg-transparent mb-4 text-white"
        id="accountNumber"
        type={showAccountNumber ? 'text' : 'password'} // Toggle between text and password
        value={accountNumber}
        onChange={(e) => setAccountNumber(e.target.value)}
        required
    />
    <span
        className="input-group-text bg-transparent text-white cursor-pointer p-0 d-flex align-items-center"
        onClick={() => setShowAccountNumber(!showAccountNumber)} // Toggle the state
        style={{ border: '1px solid white ', height: '38px' }} // Adjust the span height
    >
        {showAccountNumber ? <FaEyeSlash style={{ height: '1.2rem', width: '1.2rem' }} /> : <FaEye style={{ height: '1.2rem', width: '1.2rem' }} />}
    </span>
</div>

                    </div>

                    <div className="mb-3">
                        <label className="form-label text-white font-bold" htmlFor="accountName">Account Name</label>
                        <input
                            className="form-control bg-transparent mb-4 text-white"
                            id="accountName"
                            type="text"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="d-flex justify-content-between">
                        <button 
                            disabled={submittingWithdraw}
                            className="btn btn-sm btn-success rounded-5 w-100 text-white" 
                            type="submit"
                        >
                            {submittingWithdraw ? <Spinner /> : 'Get Withdraw'}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default GetWithdraw;
